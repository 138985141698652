import { arrayOf } from 'prop-types';
import { AlbumInterface } from '../components/AlbumGrid/albumGrid';

export const Albums: AlbumInterface[] = [
  {
    title: 'Сделай море',
    artist: 'Аффинаж',
    link: '/albums/makethesea',
    img: 'makesea-affinage.jpeg',
  },
  {
    title: 'In Rainbows',
    artist: 'Radiohead',
    link: '/albums/inrainbows',
    img: 'inrainbows-radiohead.jpeg',
  },
  {
    title: 'Sleeping With Ghosts',
    artist: 'Placebo',
    link: '/albums/sleepingwithghosts',
    img: 'sleepingwithghosts-placebo.jpeg',
  },
  {
    title: 'The Golden Age',
    artist: 'Woodkid',
    link: '/albums/thegoldenage',
    img: 'thegoldenage-woodkid.jpeg',
  },

  { img: '18-moby.jpeg', artist: 'Moby', title: '18', link: null },
  {
    img: '77-talkingheads.jpeg',
    artist: 'Talkingheads',
    title: '77',
    link: null
  },
  {
    img: 'abbeyroad-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Abbeyroad',
    link: null
  },
  {
    img: 'amelie-yanntiersen.jpeg',
    artist: 'Yanntiersen',
    title: 'Amelie',
    link: null
  },
  {
    img: 'americandream-lcdsoundsystem.jpeg',
    artist: 'Lcdsoundsystem',
    title: 'Americandream',
    link: null
  },
  {
    img: 'amnesiac-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Amnesiac',
    link: null
  },
  {
    img: 'amok-atomsforpeace.jpeg',
    artist: 'Atomsforpeace',
    title: 'Amok',
    link: null
  },
  {
    img: 'amoonshapedpool-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Amoonshapedpool',
    link: null
  },
  {
    img: 'anawesomewave-altj.jpeg',
    artist: 'Altj',
    title: 'Anawesomewave',
    link: null
  },
  {
    img: 'anima-thomyorke.jpeg',
    artist: 'Thomyorke',
    title: 'Anima',
    link: null
  },
  {
    img: 'arushofblood-coldplay.jpeg',
    artist: 'Coldplay',
    title: 'Arushofblood',
    link: null
  },
  {
    img: 'atfolsomprison-johnnycash.jpeg',
    artist: 'Johnnycash',
    title: 'Atfolsomprison',
    link: null
  },
  {
    img: 'atleastfornow-benjaminclementine.jpeg',
    artist: 'Benjaminclementine',
    title: 'Atleastfornow',
    link: null
  },
  {
    img: 'audiovideodisco-justice.jpeg',
    artist: 'Justice',
    title: 'Audiovideodisco',
    link: null
  },
  {
    img: 'blackmarketmusic-placebo.png',
    artist: 'Placebo',
    title: 'Blackmarketmusic',
    link: null
  },
  {
    img: 'coldfact-sixtorodriguez.jpeg',
    artist: 'Sixtorodriguez',
    title: 'Coldfact',
    link: null
  },
  {
    img: 'congratulations-mgmt.jpeg',
    artist: 'Mgmt',
    title: 'Congratulations',
    link: null
  },
  {
    img: 'cureforpain-morphine.jpeg',
    artist: 'Morphine',
    title: 'Cureforpain',
    link: null
  },
  {
    img: 'daydream-thelovinspoonful.jpeg',
    artist: 'Thelovinspoonful',
    title: 'Daydream',
    link: null
  },
  {
    img: 'discovery-daftpunk.jpeg',
    artist: 'Daftpunk',
    title: 'Discovery',
    link: null
  },
  {
    img: 'doolittle-pixies.jpeg',
    artist: 'Pixies',
    title: 'Doolittle',
    link: null
  },
  {
    img: 'dummy-portishead.jpeg',
    artist: 'Portishead',
    title: 'Dummy',
    link: null
  },
  {
    img: 'elcamino-theblackkeys.jpeg',
    artist: 'Theblackkeys',
    title: 'Elcamino',
    link: null
  },
  {
    img: 'electricshockblues-eels.jpeg',
    artist: 'Eels',
    title: 'Electricshockblues',
    link: null
  },
  {
    img: 'firstimpressions-thestrokes.jpeg',
    artist: 'Thestrokes',
    title: 'Firstimpressions',
    link: null
  },
  {
    img: 'good-morphine.jpeg',
    artist: 'Morphine',
    title: 'Good',
    link: null
  },
  {
    img: 'hailtothief-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Hailtothief',
    link: null
  },
  {
    img: 'highway61revisited-bobdylan.jpeg',
    artist: 'Bobdylan',
    title: 'Highway61revisited',
    link: null
  },
  {
    img: 'homework-daftpunk.jpeg',
    artist: 'Daftpunk',
    title: 'Homework',
    link: null
  },
  {
    img: 'humanafterall-daftpunk.jpeg',
    artist: 'Daftpunk',
    title: 'Humanafterall',
    link: null
  },
  {
    img: 'humanz-gorillaz.jpeg',
    artist: 'Gorillaz',
    title: 'Humanz',
    link: null
  },
  {
    img: 'hunkydory-davidbowie.jpeg',
    artist: 'Davidbowie',
    title: 'Hunkydory',
    link: null
  },
  {
    img: 'iseeyou-thexx.jpeg',
    artist: 'Thexx',
    title: 'Iseeyou',
    link: null
  },
  {
    img: 'isthisit-thestrokes.jpeg',
    artist: 'Thestrokes',
    title: 'Isthisit',
    link: null
  },
  {
    img: 'kida-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Kida',
    link: null
  },
  {
    img: 'littlecreatures-talkingheads.jpeg',
    artist: 'Talkingheads',
    title: 'Littlecreatures',
    link: null
  },
  {
    img: 'littledarkage-mgmt.jpeg',
    artist: 'Mgmt',
    title: 'Littledarkage',
    link: null
  },
  {
    img: 'magicmysterytour-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Magicmysterytour',
    link: null
  },
  {
    img: 'masters-santigold.jpeg',
    artist: 'Santigold',
    title: 'Masters',
    link: null
  },
  {
    img: 'meettheeels-eels.jpeg',
    artist: 'Eels',
    title: 'Meettheeels',
    link: null
  },
  { img: 'mgmt-mgmt.jpeg', artist: 'Mgmt', title: 'Mgmt', link: null },
  {
    img: 'moresongs-talkingheads.jpeg',
    artist: 'Talkingheads',
    title: 'Moresongs',
    link: null
  },
  {
    img: 'murmur-rem.jpeg',
    artist: 'Rem',
    title: 'Murmur',
    link: null
  },
  {
    img: 'nevermind-nirvana.jpeg',
    artist: 'Nirvana',
    title: 'Nevermind',
    link: null
  },
  {
    img: 'okcomputer-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Okcomputer',
    link: null
  },
  {
    img: 'oracular-mtmt.jpeg',
    artist: 'Mtmt',
    title: 'Oracular',
    link: null
  },
  {
    img: 'parachutes-coldplay.jpeg',
    artist: 'Coldplay',
    title: 'Parachutes',
    link: null
  },
  { img: 'play-moby.jpeg', artist: 'Moby', title: 'Play', link: null },
  {
    img: 'pleasepleaseme-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Pleasepleaseme',
    link: null
  },
  {
    img: 'randomaccessmemories-daftpunk.jpeg',
    artist: 'Daftpunk',
    title: 'Randomaccessmemories',
    link: null
  },
  {
    img: 'reflector-arcadefire.jpeg',
    artist: 'Arcadefire',
    title: 'Reflector',
    link: null
  },
  {
    img: 'relaxer-altj.jpeg',
    artist: 'Altj',
    title: 'Relaxer',
    link: null
  },
  {
    img: 'remaininlight-talkingheads.jpeg',
    artist: 'Talkingheads',
    title: 'Remaininlight',
    link: null
  },
  {
    img: 'revolver-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Revolver',
    link: null
  },
  {
    img: 'rome-dangermouse.jpeg',
    artist: 'Dangermouse',
    title: 'Rome',
    link: null
  },
  {
    img: 'rubbersoul-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Rubbersoul',
    link: null
  },
  {
    img: 'rumours-fleetwoodmac.jpeg',
    artist: 'Fleetwoodmac',
    title: 'Rumours',
    link: null
  },
  {
    img: 'russiansongs-affinage.jpeg',
    artist: 'Affinage',
    title: 'Russiansongs',
    link: null
  },
  {
    img: 'seachange-beck.jpeg',
    artist: 'Beck',
    title: 'Seachange',
    link: null
  },
  {
    img: 'secondlaw-muse.jpeg',
    artist: 'Muse',
    title: 'Secondlaw',
    link: null
  },
  {
    img: 'settle-disclosure.jpeg',
    artist: 'Disclosure',
    title: 'Settle',
    link: null
  },
  {
    img: 'sgtpepper-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Sgtpepper',
    link: null
  },
  {
    img: 'soundofsystem-lcdsoundsystem.jpeg',
    artist: 'Lcdsoundsystem',
    title: 'Soundofsystem',
    link: null
  },
  {
    img: 'strangepleasures-stillcorners.jpeg',
    artist: 'Stillcorners',
    title: 'Strangepleasures',
    link: null
  },
  {
    img: 'surferrosa-pixies.jpeg',
    artist: 'Pixies',
    title: 'Surferrosa',
    link: null
  },
  {
    img: 'thebeatles-thebeatles.jpeg',
    artist: 'Thebeatles',
    title: 'Thebeatles',
    link: null
  },
  {
    img: 'thebends-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Thebends',
    link: null
  },
  {
    img: 'thebest-thedoors.jpeg',
    artist: 'Thedoors',
    title: 'Thebest',
    link: null
  },
  {
    img: 'thedarknightofthesoul-dangermouse.jpeg',
    artist: 'Dangermouse',
    title: 'Thedarknightofthesoul',
    link: null
  },
  {
    img: 'thedarkside-pinkfloyd.jpeg',
    artist: 'Pinkfloyd',
    title: 'Thedarkside',
    link: null
  },
  {
    img: 'thegreatest-neilyoung.jpeg',
    artist: 'Neilyoung',
    title: 'Thegreatest',
    link: null
  },
  {
    img: 'thekingoflimbs-radiohead.jpeg',
    artist: 'Radiohead',
    title: 'Thekingoflimbs',
    link: null
  },
  {
    img: 'thelegend-johnnycash.jpeg',
    artist: 'Johnnycash',
    title: 'Thelegend',
    link: null
  },
  {
    img: 'thesound-thesmiths.jpeg',
    artist: 'Thesmiths',
    title: 'Thesound',
    link: null
  },
  {
    img: 'thesuburbs-arcadefire.jpeg',
    artist: 'Arcadefire',
    title: 'Thesuburbs',
    link: null
  },
  {
    img: 'thevelvetundeground-thevelvetundeground.jpeg',
    artist: 'Thevelvetundeground',
    title: 'Thevelvetundeground',
    link: null
  },
  {
    img: 'thevelvetundegroundandnico-thevelvetundeground.jpeg',
    artist: 'Thevelvetundeground',
    title: 'Thevelvetundegroundandnico',
    link: null
  },
  {
    img: 'thisisallyours-altj.jpeg',
    artist: 'Altj',
    title: 'Thisisallyours',
    link: null
  },
  {
    img: 'transformer-loureed.jpeg',
    artist: 'Loureed',
    title: 'Transformer',
    link: null
  },
  {
    img: 'turnblue-theblackkeys.jpeg',
    artist: 'Theblackkeys',
    title: 'Turnblue',
    link: null
  },
  {
    img: 'tyranny-thevoidz.jpeg',
    artist: 'Thevoidz',
    title: 'Tyranny',
    link: null
  },
  {
    img: 'vivalavida-coldplay.jpeg',
    artist: 'Coldplay',
    title: 'Vivalavida',
    link: null
  },
  {
    img: 'wewillalwaysloveyou-theavalanches.jpeg',
    artist: 'Theavalanches',
    title: 'Wewillalwaysloveyou',
    link: null
  },
  {
    img: 'whatsgoingon-marvingaye.jpeg',
    artist: 'Marvingaye',
    title: 'Whatsgoingon',
    link: null
  },
  {
    img: 'woman-justice.jpeg',
    artist: 'Justice',
    title: 'Woman',
    link: null
  },
  {
    img: 'xandy-coldplay.jpeg',
    artist: 'Coldplay',
    title: 'Xandy',
    link: null
  },
  { img: 'xx-thexx.jpeg', artist: 'Thexx', title: 'Xx', link: null },
  {
    img: 'yes-morphine.jpeg',
    artist: 'Morphine',
    title: 'Yes',
    link: null
  },
  {
    img: 'ziggy-davidbowie.jpeg',
    artist: 'Davidbowie',
    title: 'Ziggy',
    link: null
  }

];

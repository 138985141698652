import { Link } from 'gatsby';
import React from 'react';
import styles from './albumGrid.module.css';
import AlbumGridItem from './AlbumGridItem/albumGridItem';

export interface AlbumInterface {
  title: string;
  artist: string;
  img: string;
  link: string;
}

interface AlbumGridProps {
  albums: AlbumInterface[];
  lang: 'ru' | 'en';
}

const AlbumGrid = ({ albums, lang }: AlbumGridProps) => {
  return (
        <div className={styles['AlbumGrid']}>
            {albums.map(val => <AlbumGridItem lang={lang} album={val}/>)}
        </div>
  );
};

AlbumGrid.defaultProps = {
  lang: 'ru',
};

export default AlbumGrid;

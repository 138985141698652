import { Link } from 'gatsby';
import React from 'react';
import PlaceImage from '../../Blocks/PlaceImage/placeImage';
import { AlbumInterface } from '../albumGrid';
import styles from './albumGridItem.module.css';
import Tilt from 'react-parallax-tilt';

interface AlbumGridItemProps {
  album: AlbumInterface;
  lang: 'ru' | 'en';
}

const AlbumGridItem = ({ album, lang }: AlbumGridItemProps) => {
  return (
    album.link 
    ?
    <Tilt gyroscope={true} reset={false} scale={1.5} transitionSpeed={2500}>
        <Link title={album.title} className={styles['AlbumGridItem']} to={lang === 'ru' ? album.link : `/en${album.link}` } >
            <PlaceImage nozoom filename={album.img} alt={album.title}/>
        </Link>
    </Tilt>
    : 
    <Tilt gyroscope={true} reset={false} scale={1.5} transitionSpeed={2500}>
        <div title={album.title} className={styles['AlbumGridItem']} >
            <PlaceImage nozoom filename={album.img} alt={album.title}/>
        </div>
    </Tilt>
  );
};

export default AlbumGridItem;

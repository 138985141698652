import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import AlbumGrid, { AlbumInterface } from '../components/AlbumGrid/albumGrid';
import Layout from '../components/layout';
import SEO from '../components/SEO/seo';
import { Albums } from '../data/albumData';

const AlbumsPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  return (
    <Layout >
         <SEO lang="en" description="Best albums of all time according to Artyom Alekseev" title={'Albums'} keywords={['designer', 'artyom', 'alekseev', 'Artyom Alekseev', 'ux', 'ui']}>
        </SEO>
        <AlbumGrid lang="en" albums={Albums}/>
    </Layout>

  ); };

export default AlbumsPage;
